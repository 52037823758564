import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { FormBuilder } from '@angular/forms';
import { HistoryService } from 'src/app/services/history.service';
import { History } from '../models/config.model';
import { process, State, GroupDescriptor, groupBy, GroupResult } from "@progress/kendo-data-query";
import { UserAuthService } from '../auth/services/user-auth.service';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }

}
