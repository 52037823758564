<div class="card-box" *ngIf="disableJobSetupTab">
  Tab disabled, Please configure Client first
</div>
<div class="card-box" *ngIf="!disableJobSetupTab">
  <form class="k-form" [formGroup]="jobSetupForm">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Frequency">
            <kendo-dropdownlist
              [data]="filteredFrequencyData"
              textField="frequency"
              valueField="id"
              [formControlName]="'jobFrequencyId'"
              [filterable]="true"
              (selectionChange)="frequencySelectionChange($event)"
              (filterChange)="frquencyFilter($event)"
              #frequency
              required
            >
            </kendo-dropdownlist>
          </kendo-floatinglabel>
          <kendo-formerror>Frequency required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div
        class="col-md-3 col-sm-12 mb-4"
        *ngIf="selectedFrequency === 'Monthly'"
      >
        <kendo-formfield>
          <kendo-floatinglabel text="Select Date of Month">
            <kendo-numerictextbox
              [style.width.px]="170"
              [formControlName]="'dayOfWeekMonth'"
              [autoCorrect]="autoCorrect"
              [min]="minDate"
              [max]="maxDate"
              format="n"
              required
            ></kendo-numerictextbox>
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
      <div
        class="col-md-3 col-sm-12 mb-4"
        *ngIf="selectedFrequency === 'Weekly'"
      >
        <kendo-formfield>
          <kendo-floatinglabel text="Select Day of Week">
            <kendo-dropdownlist
              [formControlName]="'dayOfWeekMonth'"
              textField="text"
              valueField="value"
              [data]="weekDays"
              (filterChange)="daysFilter($event)"
              [style.width.px]="170"
              required
            >
            </kendo-dropdownlist>
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
      <div
        class="col-md-3 col-sm-12 mb-4"
        *ngIf="selectedFrequency === 'Hourly'"
      >
        <kendo-formfield>
          <kendo-floatinglabel text="Hourly Duration">
            <kendo-numerictextbox
              [style.width.px]="170"
              [formControlName]="'jobDuration'"
              [autoCorrect]="autoCorrect"
              [min]="minHour"
              [max]="maxHour"
              format="n"
              required
            ></kendo-numerictextbox>
          </kendo-floatinglabel>
        </kendo-formfield>
      </div>
      <div
        class="col-md-3 col-sm-12 mb-4"
        *ngIf="selectedFrequency === 'Minutes'"
      >
        <kendo-formfield>
          <kendo-label
            text="Duration in Minute"
            style="margin-bottom: 8px !important"
          ></kendo-label>
          <kendo-numerictextbox
            [style.width.px]="170"
            [autoCorrect]="autoCorrect"
            [max]="max"
            [min]="min"
            format="n"
            [formControlName]="'jobDuration'"
            required
          ></kendo-numerictextbox>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="Job Name">
            <kendo-textbox [readonly]="isJobSetupDisabled" [formControlName]="'jobName'" required>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Job Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-label
            text="Job Start Time (in UTC)"
            style="margin-bottom: 10px !important"
          ></kendo-label>
          <kendo-datetimepicker
            format="MM/dd/yyyy HH:mm"
            [value]="selDate"
            [rounded]="rounded"
            [fillMode]="fillMode"
            [size]="size"
            calendarType="infinite"
            [formControlName]="'jobStartTime'"
            required
          ></kendo-datetimepicker>
          <kendo-formerror>Job Start Time is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-floatinglabel text="Retry Job Count in Case of Failure">
          <kendo-numerictextbox
            [formControlName]="'retryJobCount'"
            format="n"
            [min]="0"
            [max]="3"
          >
          </kendo-numerictextbox>
        </kendo-floatinglabel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 ">
        <button
          kendoButton
          _ngcontent-whw-c5
          style="color: #fff; background-color: #193971 !important"
          [routerLink]="['../ClientSetup']"
          (click)="exit()"
        >
          Back
        </button>
        <kendo-button
          _ngcontent-whw-c5
          style="
            color: #fff;
            background-color: #193971 !important;
            margin-left: 30px;
          "
          (click)="saveJobSetup()"
          [disabled]="disabled()"
          >Save</kendo-button
        >
      </div>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>
