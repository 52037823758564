import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

import { UserAuthService } from 'src/app/auth/services/user-auth.service';
import { Auth, UrlGroup } from '../models/config.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CallbackGaurdService {
  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  get auth(): Auth {
    return this.configService.getAuthData('auth');
  }

  constructor(public authService: AuthService, public router: Router, private UserService: UserService, public UserAuthService: UserAuthService, private configService: ConfigService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated$.pipe(
      //delay(1000),
      tap(loggedIn => {
        if (loggedIn) {
          this.authService.user$.subscribe(user => {
            let clientId = this.auth.ClientID;
            let ClientSecret = this.auth.ClientSecret;
            let data = {
              'clientId': clientId,
              'ClientSecret': ClientSecret
            };
            this.UserAuthService.getAuthenticationToken(data).subscribe(authTokenData => {
              localStorage.setItem("token", authTokenData['access_token']);
              localStorage.setItem("expires_in", authTokenData['expires_in']);
              this.UserService.getUserInfoByUserEmail(user.email).subscribe(res => {
                if (res && res.isActive) {
                  console.log(res);
                  let userdata = {

                    'id': res.id,
                    'userId': res.userId
                  };
                  console.log(userdata);
                 this.UserService.updateUserLastLogin(userdata).subscribe(res => console.log(res) )
                  localStorage.setItem("role", res.role);
                  this.router.navigate(['/ClientSetup']);
                } else {
                  this.router.navigate(['/not-authorized']);
                }
                if (!res) {
                  this.router.navigate(['/not-authorized']);
                }
              }),
              error1 =>{
                this.router.navigate(['/not-authorized']);
              }
            }, error => {
              this.router.navigate(['/not-authorized']);
            })
          })
        } else
          if (!loggedIn) {
            this.router.navigate(['/not-authorized']);
          }
      }),
    );

    // return new Promise((resolve, reject) => {
    //   this.AuthenticateService.handleAuthentication()
    //     .then(() => {
    //       //this.authService.scheduleRenewal();
    //       resolve(true);
    //     });
    // });
  }
}
