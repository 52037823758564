<app-contentheader headerText="Claim History"></app-contentheader>
<div class="card-box">
    <form class="k-form">
      <div class="row">
        <kendo-grid [reorderable]="true" [kendoGridBinding]="historyApiData" filterable="menu" [resizable]="true"
          [sortable]="true" [groupable]="true" [group]="state.group" [pageSize]="state.take" [skip]="state.skip"
          [pageable]="{
            pageSizes: pageSizes
          }" scrollable="scrollable" style="color: #fff" cssClass="grid-height">
          <ng-template kendoGridToolbarTemplate>

            <kendo-grid-spacer></kendo-grid-spacer>

            <button kendoGridExcelCommand type="button" icon="file-excel" title="Download Excel"></button>
            <button kendoGridPDFCommand type="button" icon="file-pdf" title="Download Pdf"></button>
            <kendo-grid-column-chooser></kendo-grid-column-chooser>
          </ng-template>
          <kendo-grid-column field="id" title="Id" [width]="70" [headerClass]="'headerCustomClass'"
            filter="numeric"></kendo-grid-column>
          <kendo-grid-column field="clientName" title="Client Name" [width]="110"
            [headerClass]="'headerCustomClass'"></kendo-grid-column>

          <kendo-grid-column field="clientJobSetupName" title="Job Setup Name" [width]="130"
            [headerClass]="'headerCustomClass'"></kendo-grid-column>

          <kendo-grid-column field="processedClaimId" title="Claim Id" [width]="90" [headerClass]="'headerCustomClass'">
          </kendo-grid-column>
          <kendo-grid-column field="targetReferenceId" title="Target RefId" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="fileName" title="File Name" [width]="130">
          </kendo-grid-column>
          <kendo-grid-column field="status" title="Status" [headerClass]="'headerCustomClass'"
            [width]="120"></kendo-grid-column>

          <kendo-grid-column field="jsonData" title="JSON Data" [filterable]="false" [headerClass]="'headerCustomClass'"
            [width]="110"><ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton style="color: #fff; background-color: #193971 !important"
                (click)="getJsonDataByClientId(dataItem.id)">
                Show Json
              </button>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="message" title="Message" [filterable]="false" [headerClass]="'headerCustomClass'"
            [width]="120"><ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton style="color: #fff; background-color: #193971 !important"
                (click)="openmessage(dataItem.message)">
                Show Message
              </button>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="isEmailSent" filter="boolean" title="Is Email Sent" [width]="150"
            [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="clientId" title="Client Id" [width]="120" [headerClass]="'headerCustomClass'"
            filter="numeric" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="clientJobSetupId" title="Client Job Setup Id" [width]="190"
            [headerClass]="'headerCustomClass'" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="processedTriggerObjectId" title="Processed Trigger Object Id" [width]="240"
            [hidden]="true">
          </kendo-grid-column>

          <kendo-grid-column field="jobRetriedCount" title="Job Retried Count" [width]="170" [hidden]="true">
          </kendo-grid-column>

          <kendo-grid-column field="createdBy" title="Created By" [hidden]="true" [headerClass]="'headerCustomClass'"
            [width]="150"></kendo-grid-column>
          <kendo-grid-column field="modifiedBy" title="Modified By" [hidden]="true" [headerClass]="'headerCustomClass'"
            [width]="150"></kendo-grid-column>
          <kendo-grid-column field="modifiedDate" title="Modified Date" [hidden]="true"
            [headerClass]="'headerCustomClass'" [width]="150"></kendo-grid-column>
          <kendo-grid-pdf [fileName]="'History.pdf'">
          </kendo-grid-pdf>
          <kendo-grid-excel fileName="History.xlsx"></kendo-grid-excel>
        </kendo-grid>
        <ng-container *ngIf="ismessageTextboxVisible">
          <kendo-dialog [title]="Title" *ngIf="openedmessage" (close)="close('cancel')">
            <div class="textareawrapmsg">
              <kendo-textarea #Textbox resizable="none" [(value)]="message" [readonly]="true"
                style="width: 800px; height: 100px;"></kendo-textarea>
            </div>
          </kendo-dialog>
        </ng-container>
        <ng-container *ngIf="isTextareaVisible">
          <kendo-dialog [title]="Title" *ngIf="opened" (close)="close('cancel')">
            <div class="textareawrap">
              <kendo-textarea #Textarea resizable="none" [(value)]="jsonData" [readonly]="true"
                style="width: 900px; overflow: hidden;"></kendo-textarea>
            </div>
          </kendo-dialog>
        </ng-container>
      </div>
    </form>
  </div>
