import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { process } from "@progress/kendo-data-query";
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/models/config.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-allsourcesystem',
  templateUrl: './allsourcesystem.component.html',
  styleUrls: ['./allsourcesystem.component.scss']
})
export class AllsourcesystemComponent implements OnInit {

  public isTextboxVisible: boolean = false;
  public isaddTextboxVisible: boolean = false;
  public openedEdit = false;
  public newData: any = '';
  public opened = false;
  public FileName: string = "";
  public newName: string = '';
  @ViewChild('editDialog') editDialog!: DialogRef;
  public name: string = "";
  public Title: string = "";
  public isEditMode: boolean = false;
  @Input()
  id: number = 0;
  public sourceSystem: Sourcesystem[] = [];
  public Data = [];
  public sourcesystem: Sourcesystem[] = [];
  validPattern = "^[a-zA-Z0-9]{10}$"
  public sourcesystemForm: FormGroup;
  LoggedInName: string;
  public pageSizes = true;
  get getFormControl() { return this.sourcesystemForm.controls; }

  constructor(private dialogService: DialogService, @Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.sourcesystemForm = this.fb.group({
      id: new FormControl(),
      name: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9]*$")]),
      isActive: new FormControl(),
      createdBy: new FormControl(),
    })
    this.allData = this.allData.bind(this);

  }
  public allData():  ExcelExportData{
    const result: ExcelExportData = {
      data: process(this.sourceSystem, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  ngOnInit(): void {
    if (this.name == '') {
      this.isEditMode = true;
      this.getSourceSystemData();
      console.log('getting ng on it')
      console.log(this.isEditMode);
    }
    console.log('data from read api:');
    this.masterdataservice.getAllSourceSystem().subscribe(data => {
      this.sourceSystem = data.reverse();
    });
  }

  public getFormData(): any {
    this.LoggedInName = localStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: Sourcesystem = new Sourcesystem();
    const formValue = this.sourcesystemForm.getRawValue();
    console.log(this.sourcesystemForm.getRawValue());
    if (this.isEditMode) {
      data.id = formValue.id;
      data.name = formValue.name;
      data.isActive = formValue.isActive;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.name = formValue.name;
      data.createdBy = this.LoggedInName;
      data.isActive = true;


    }

    return (data);
  }

  public openDialog(name: string, id: number, isActive: boolean) {
    this.isEditMode = true;
    this.name = name;
    this.id = id;
    this.sourcesystemForm.patchValue({ id: id });
    this.sourcesystemForm.patchValue({ name: name });
    this.sourcesystemForm.patchValue({ isActive: isActive });
    this.Title = "Edit Source System";
    this.openedEdit = true;
    console.log(this.name);
    this.showTextbox();
  }

  // public onCancel(){
  //   this.editDialog.close();
  // }

  public close(): void {
    this.isEditMode = false;
    this.name = null;
    this.opened = false;
    this.openedEdit = false;
  }

  public open(): void {
    this.opened = true;
    this.isEditMode = false;
    this.showaddTextbox();
    this.Title = "Add Source System";
    this.sourcesystemForm = this.fb.group({
      id: new FormControl(),
      name: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      isActive: new FormControl(),
      createdBy: new FormControl(),
    })
  }

  public showaddTextbox() {
    this.isaddTextboxVisible = true;
  }

  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }
  public saveData() {
    let data = this.getFormData();
    console.log(data);
    if (this.name && this.sourcesystemForm.touched && this.isEditMode) {
      this.putSourcesystemData(data);
    } else {
      this.postSourcesystemData(data);
    }
  }

  public putSourcesystemData(data: any){
    this.masterdataservice.putAllSourceSystem(data).subscribe((resp) => {
      this.name = resp.name;
      if (resp.isSuccess) {
        this.showConfirmation("Success","Source system updated successfully");
         console.log("Source system updated successfully")
        // this.masterdataservice.getAllSourceSystem();
      } else {
        this.showConfirmation("Error","Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }


  public postSourcesystemData(data: any) {
    console.log(data);
    this.masterdataservice.postAllSourceSystem(data).
      subscribe((resp) => {
        //this.name = resp.name;
        // this.createdBy = resp.createdBy;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Source system added successfully");
          console.log("Source system added successfully");
          //this.postAllSourceSystem();
          // this.getSourceSystemData();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log(error.message);
      });
  }
  public getSourceSystemData() {
      this.masterdataservice.getAllSourceSystem().subscribe(data => {
        this.sourceSystem = data.reverse();
        if (this.sourceSystem) {
          this.sourcesystemForm.setValue(this.sourceSystem);
          console.log(this.sourceSystem);
        }
        console.log(this.sourceSystem)
      });

  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 180,
      minWidth: 250,
    })
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        // // window.location.reload()
        // this.getSourceSystemData();
        // // this.opened = false;
        // if(this.isEditMode){
        // this.openedEdit = false;
        // }
        // else{
        // this.opened = false;

        // }
        // console.log("close");
      } else {
        this.opened = false;
        this.openedEdit = false;
        this.getSourceSystemData();
        // window.location.reload();
        console.log("action", result);
      }
      this.result = JSON.stringify(result);
    });
  }
}
