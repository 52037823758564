import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { EmailConfigurationService } from 'src/app/services/email-configuration.service';
import { EmailConfig } from 'src/app/models/config.model';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { process } from "@progress/kendo-data-query";
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Client } from 'src/app/interfaces/client';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-allemailconfig',
  templateUrl: './allemailconfig.component.html',
  styleUrls: ['./allemailconfig.component.scss']
})
export class AllemailconfigComponent {
  public triggerTypeId: number = 0;
  public id: number;
  public clientName: string='';
  public clientId: number = 0;
  public showEmailTemplate: boolean = false;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | undefined;

  public emailconfigData: EmailConfig[] = [];

  public emailGridView: EmailConfig[] = [];
  public triggerTypeData: Triggertype[]=[];
  public clientData: Client[]=[];
  public client: Client;
  public triggerType: Triggertype;

  public mySelection: string[] = [];
  public pageSizes:boolean = true;
  constructor(@Inject(EmailConfigurationService) private emailconfigurationService: EmailConfigurationService,@Inject(TriggerEventService) private triggerEventService: TriggerEventService,@Inject(MasterdataService) private masterdataService: MasterdataService) {
    this.masterdataService.getAllTriggerType().subscribe(data=>{
      this.triggerTypeData = data.filter(s => s.isActive == true);
    });

    this.masterdataService.getAllClient().subscribe(data=>{
      this.clientData = data.filter(s => s.isActive == true);
    });
    this.allData = this.allData.bind(this);

  }

  public ngOnInit(): void {
    this.emailconfigurationService.getClientEmailTemplateDetails(this.clientId).subscribe(data => {
      this.emailconfigData = data;
      this.emailGridView = this.emailconfigData.reverse();
  });
}
public allData():  ExcelExportData{
  const result: ExcelExportData = {
    data: process(this.emailGridView, {

      sort: [{ field: "id", dir: "desc" }],
    }).data,


  };
  return result;
}

  public showGrid(): void {
    this.showEmailTemplate = false;
  }

  public addEmailtemplate(): void {
    this.showEmailTemplate = true;
    this.clientId = 0;
  }

  public editEmail(value: EmailConfig): void {
    this.id = value.id;
    this.clientId = value.clientId;
    this.clientName=value.clientName;
    console.log(this.client);
    this.triggerTypeId=value.triggerTypeId;
    this.showEmailTemplate = true;
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.emailGridView = process(this.emailconfigData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "clientId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "triggerType",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "id",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "clientName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "toEmails",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "subject",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "ccEmail",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "bccEmail",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "body",
            operator: "contains",
            value: inputValue,
          },

          {
            field: "modifiedBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedDate",
            operator: "contains",
            value: inputValue,
          }

        ],
      },
    }).data;
    // this.dataBinding?.skip = 0;
  }
}


