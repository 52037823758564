import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Trackedtables } from 'src/app/interfaces/trackedtables';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { Timecode } from 'src/app/interfaces/timecode';
import { Expensecode } from 'src/app/models/config.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';


@Component({
  selector: 'app-allexpensecode',
  templateUrl: './allexpensecode.component.html',
  styleUrls: ['./allexpensecode.component.scss']
})
export class AllexpensecodeComponent implements OnInit {

  public isTextboxVisible: boolean = false;
  public newData: any = '';
  public opened = false;
  public FileName: string = "";
  public newName: string = '';

  @ViewChild('editDialog') editDialog!: DialogRef;

  public code: string = "";
  public Title: string = "";
  public formData = { Name: '' };
  public formValid = false;
  public SourcesystemApiData: Sourcesystem;
  public isEditMode: boolean = false;
 public pageSizes:boolean = true;
  @Input()
  id: number = 0;

  public expenseCode: Expensecode[] = [];
  public Data = [];
  public expensecodeForm: FormGroup;
  LoggedInName: string;
  get getFormControl() { return this.expensecodeForm.controls; }

  constructor(private dialogService: DialogService, @Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.expensecodeForm = this.fb.group({
      id: new FormControl(),
    isActive: new FormControl(true),
    code: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
    createdBy: new FormControl()
    })
    this.allData = this.allData.bind(this);

  }
  public allData():  ExcelExportData{
    const result: ExcelExportData = {
      data: process(this.expenseCode, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  ngOnInit(): void {
    if (this.code == '') {
      this.isEditMode = true;
      this.getExpensecodeData();
    }
    console.log(this.isEditMode);
    console.log('data from read api:');
    this.masterdataservice.getAllExpensecode().subscribe(data => {
      this.expenseCode = data.reverse();
      console.log(data);
    });
  }

  public getFormData(): any {
    this.LoggedInName = localStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: Expensecode = new Expensecode();
    const formValue = this.expensecodeForm.getRawValue();
    if (this.isEditMode) {
      data.code = formValue.code;
      data.isActive = formValue.isActive;
      data.id = formValue.id;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.code = formValue.code;
      data.createdBy = this.LoggedInName;
      data.isActive = true;

    }
    return (data);
  }

  public openDialog(code: string, id: number, isActive: boolean) {
    this.isEditMode = true;
    this.code = code;
    this.id = id;
    this.expensecodeForm.patchValue({ code: code });
    this.expensecodeForm.patchValue({ id: id });
    this.expensecodeForm.patchValue({ isActive: isActive });
    this.Title = "Edit Expense code";
    this.opened = true;
    console.log(this.code);
    this.showTextbox();
  }

  public close() {
    this.opened = false;
  }

  public open(): void {
    this.isEditMode = false;
    this.opened = true;
    this.showTextbox();
    this.Title = "Add Expense code";
    this.expensecodeForm = this.fb.group({
      code: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      isActive:[],
      id:[]

    })
  }


  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }

  public saveData() {
    let data = this.getFormData();
    console.log(data);
    if (this.code && this.isEditMode) {
      this.putExpensecodeData(data);
    } else {
      this.postExpensecodeData(data);
    }
    // this.getExpensecodeData();
    // this.masterdataservice.getAllExpensecode().subscribe(data => {
    //   this.expenseCode = data;
    // });
  }

  public putExpensecodeData(data: any) {
    this.masterdataservice.putAllExpensecode(data).subscribe((resp) => {
      this.code = resp.code;
      // this.id = resp.id
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Expense code updated successfully");
        console.log("Expense code updated successfully");

        // this.masterdataservice.getAllExpensecode();
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postExpensecodeData(data: any) {
    console.log(data);
    this.masterdataservice.postAllExpensecode(data).
      subscribe((resp) => {
        // this.code = resp.code;
        if (resp.isSuccess) {
          this.showConfirmation("Success","Expense Code added successfully");
          console.log("Expense Code added successfully");
          // this.getExpensecodeData();
        }
        else {
          this.showConfirmation("Error","Error: " + resp.message);
          console.log("Error: " + resp.message);
        }
      }, (error: any) => {
        console.log(error.message);
      });
  }

  public getExpensecodeData() {

      this.masterdataservice.getAllExpensecode().subscribe(data => {
        this.expenseCode = data.reverse();
        if (this.expenseCode) {
          this.expensecodeForm.setValue(this.expenseCode);
        }
      });

  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {

      } else {
        console.log("action", result);
        this.opened = false;
        this.getExpensecodeData();
        // window.location.reload();

      }
      this.result = JSON.stringify(result);
    });
  }

}
