import { Component, Inject, Input, HostListener } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-upload/events/select-event';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { JobSetup } from 'src/app/models/config.model';
import { Jobfrequency } from 'src/app/interfaces/jobfrequency';
import { JobName } from 'src/app/interfaces/jobName';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DateInputSize, DateInputRounded, DateInputFillMode } from "@progress/kendo-angular-dateinputs";

@Component({
  selector: 'app-job-setup',
  templateUrl: './job-setup.component.html',
  styleUrls: ['./job-setup.component.scss']
})
export class JobSetupComponent {
  public jobSetupApiData: JobSetup;
  public disableJobSetupTab: boolean;
  public showAlert: boolean = true;
  public date: Date = new Date();
  public selectedFrequency: string | undefined;
  public autoCorrect = true;
  public minDate = 1;
  public maxDate = 31;
  public minHour = 1;
  public maxHour = 23;
  public min = 0;
  public max = 59;
  public format = "MM/dd/yyyy HH:mm";
  public selDate: Date = new Date(2019, 5, 1, 21, 45);
  public selectedJobFrequencyValue: Number = 0;
  public selectedDayOfWeekMonthValue: Number = 0;
  //public frequencySource: Jobfrequency[] = [];
  //public frequencies: Jobfrequency[] = [];
  @Input() frequecyMasterData:Jobfrequency[] = [];

  public filteredFrequencyData : Jobfrequency[] = [];
  public selTriggerType: string;

  public weekDaySource: Array<{ text: string; value: number }> = [
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thursday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
    { text: "Sunday", value: 7 },
  ];

  public weekDays: Array<{ text: string; value: number }>;

  @Input()
  clientConfigurationId: number = 0;
  clientJobSetupId: number = 0;
  clientId: number = 0;
  clientName: string;
  sourceSystem: string;
  orgCode: string;

  public isEditMode: boolean = false;
  public isJobSetupDisabled: boolean = false;
  public rounded: DateInputRounded = "small";
  public fillMode: DateInputFillMode = "flat";
  public size: DateInputSize = "small";

  public jobfreq: any = {};

  public jobSetupForm: FormGroup;
  LoggedInName: string = "";


  constructor(@Inject(MasterdataService) private masterdataService: MasterdataService, @Inject(JobSetupService) private jobSetupService: JobSetupService, private fb: FormBuilder, private dialogService: DialogService) {
    // this.masterdataService.getAllJobfrequency().subscribe(data => {
    //   this.frequencySource = data;
    //   this.frequencies = data;
    // });

    this.clientId = Number(localStorage.getItem('clientId'));
    this.weekDays = this.weekDaySource.slice();

    this.jobSetupForm = this.fb.group({
      clientJobSetupId: new FormControl(),
      clientId: new FormControl(),
      clientConfigurationId: new FormControl(),
      jobFrequencyId: new FormControl([Validators.required]),
      jobDuration: new FormControl(this.jobfreq.jobDuration, [Validators.required]),
      isActive: new FormControl(),
      dayOfWeekMonth: new FormControl(this.jobfreq.dayOfWeekMonth, [Validators.required]),
      jobName: new FormControl(this.jobfreq.jobName, [Validators.required]),
      jobStartTime: new FormControl(this.jobfreq.jobStartTime, [Validators.required]),
      retryJobCount: new FormControl()
    })
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    this.clientName = localStorage.getItem('clientName');
    this.sourceSystem = localStorage.getItem('sourceSystem');
    this.orgCode = localStorage.getItem('orgCode');
    if (!this.clientName || !this.sourceSystem || !this.orgCode) {
      this.disableJobSetupTab = true;
      this.showConfirmation("Tab disabled", "Please configure client first");
    }
  }

  ngOnInit(): void {
    this.filteredFrequencyData = this.frequecyMasterData;
    this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    if (this.clientConfigurationId > 0) {
      this.getJobSetupDetails(this.clientConfigurationId);
      if(this.clientJobSetupId>0){
      this.isEditMode = true;
      }
    }
  }
  public isJobNameDisabled(jobName: any): void {
    if(jobName!=='' || jobName == undefined){
      this.isJobSetupDisabled = true;
    }
    else{
      this.isJobSetupDisabled = false;
    }
  }

  public frequencySelectionChange(value: any): void {
    this.selectedFrequency = value.frequency;
  }

  public getFormData(): any {
    let data: JobSetup = new JobSetup();
    this.LoggedInName = localStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    const formValue = this.jobSetupForm.getRawValue();
    if (+formValue.clientJobSetupId > 0) {
      data.id = formValue.clientJobSetupId
    }
    if (this.clientConfigurationId <= 0) {
      this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    }
    data.clientConfigurationId = this.clientConfigurationId;
    data.clientId = Number(localStorage.getItem('clientId'));
    data.jobFrequencyId = formValue.jobFrequencyId.id;
    data.jobDuration = formValue.jobDuration;
    data.isActive = Boolean(JSON.parse(localStorage.getItem('isActive')));
    let dayofWeekOrMonth;
    if (formValue.jobFrequencyId.frequency == 'Monthly') {
      dayofWeekOrMonth = formValue.dayOfWeekMonth;
    }
    else if (formValue.jobFrequencyId.frequency == 'Weekly') {
      dayofWeekOrMonth = formValue.dayOfWeekMonth.value;
    }
    else {
      dayofWeekOrMonth = null;
    }
    data.dayOfWeekMonth = dayofWeekOrMonth;
    data.jobName = formValue.jobName;

    data.jobStartTime = formValue.jobStartTime.toLocaleString("en-us");
    data.retryJobCount = formValue.retryJobCount;
    if (+this.isEditMode > 0) {
      data.modifiedBy = this.LoggedInName;

    }
    else {
      data.createdBy = this.LoggedInName;
    }
    return data;
  }

  public saveJobSetup(): void {
    let data = this.getFormData();
    if (+this.clientJobSetupId > 0) {
      this.updateClientJobSetup(data);
    } else {
      this.createClientJobSetup(data);
    }
  }

  public updateClientJobSetup(data: any) {
    this.jobSetupService.putClientJobSetup(data).subscribe((resp) => {
      if (resp.isSuccess) {
        console.log("Client job setup updated successfully");

        this.showConfirmation("Success", "Client job setup updated successfully");
        this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
        this.getJobSetupDetails(this.clientConfigurationId);
      } else {

        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public createClientJobSetup(data: any) {
    this.jobSetupService.postClientJobSetup(data).subscribe((resp) => {
      // this.clientJobSetupId = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client job setup updated successfully");
        //need to fetch recently created data using resp.id which has value of clientConfigurationId
        //now but will need clientJobSetupId to fetch ClientJobSetup data instead clientConfigurationId
        this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
        this.getJobSetupDetails(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("error1");
    });
  }



  frquencyFilter(value: any) {
    // this.frequencySource = this.frequencies;
    // this.frequencySource = this.frequencies.filter(
    //   (s) => s.frequency.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // );
    this.filteredFrequencyData = this.frequecyMasterData;
    this.filteredFrequencyData = this.frequecyMasterData.filter(
        (s) => s.frequency.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  daysFilter(value: any) {
    this.weekDays = this.weekDaySource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public getJobSetupDetails(clientConfigurationId: number) {
    this.jobSetupService.GetClientJobSetUpByClientConfigurationId(clientConfigurationId).subscribe(jobSetupApiData => {
      if (jobSetupApiData) {
        let getFrequency = this.frequecyMasterData.find(item => item.id === jobSetupApiData.jobFrequencyId);
        this.selectedFrequency = this.frequecyMasterData.find(item => item.id === jobSetupApiData.jobFrequencyId)?.frequency;
        this.clientJobSetupId =jobSetupApiData.clientJobSetupId;
        this.jobSetupForm.patchValue({
          clientJobSetupId: jobSetupApiData.clientJobSetupId,
          clientConfigurationId: Number(localStorage.getItem('clientConfigurationId')),
          clientId: Number(localStorage.getItem('clientId')),
          jobName:jobSetupApiData.jobName,
          dayOfWeekMonth:jobSetupApiData.jobFrequencyId === 4 ? this.weekDays.find(item => item.value === jobSetupApiData.dayOfWeekMonth) : jobSetupApiData.dayOfWeekMonth,
          isActive:jobSetupApiData.isActive,
          jobDuration: jobSetupApiData.jobDuration,
          jobFrequencyId: getFrequency,

          jobStartTime: (jobSetupApiData.jobStartTime == "Invalid Date" || jobSetupApiData.jobStartTime == null) ? '' : new Date(jobSetupApiData.jobStartTime?.toString()),
          retryJobCount: jobSetupApiData.retryJobCount,
        });
        this.selDate = new Date(jobSetupApiData.jobStartTime?.toString());
        this.isJobNameDisabled(jobSetupApiData.jobName);
      }
      this.selTriggerType = localStorage.getItem('triggerType').toString();
    });
  }

  public exit() {
    if(this.jobSetupForm.dirty){
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    }else{
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
        window.location.reload();
      }
      this.result = JSON.stringify(result);
    });
  }

  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes"}, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
       if(result.text == "Yes"){
        //this._location.back();
        window.location.reload();
       }
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.clientConfigurationId > 0) {
      if(this.clientJobSetupId>0){
      this.isEditMode = true;
      }
    }
    if (this.isEditMode) {
      if (!this.jobSetupForm.valid || !this.jobSetupForm.dirty) {
        return true;
      }
    } else {
      if (!this.jobSetupForm.valid) {
        return true;
      }
    }
    return false;
  }
}
