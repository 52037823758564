import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from '../services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Trackedtables } from 'src/app/interfaces/trackedtables';
import { Triggertype } from 'src/app/interfaces/triggertype';




@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.scss']
})
export class MasterdataComponent {
  public userRole: string = "";


  @Input()
  id: number;
  constructor() { }

  ngOnInit(): void {
    //console.log(this.id);
    this.userRole = localStorage.getItem('role').toLowerCase();
  }

  public exit() {
    window.location.reload();
  }

  


}