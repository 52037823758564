import { Component, Inject, OnInit } from '@angular/core';
import { TwilioSmsClaimdetailsService } from '../../services/twilio-sms-claimdetails.service';
import { FormBuilder } from '@angular/forms';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { TwilioSMSClaimDetails } from '../../models/config.model';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';



@Component({
  selector: 'app-twilio-smsclaim-details',
  templateUrl: './twilio-smsclaim-details.component.html',
  styleUrls: ['./twilio-smsclaim-details.component.scss']
})
export class TwilioSMSClaimDetailsComponent implements OnInit {
  isTextareaVisible: boolean;
  opened: boolean;
  Title: string;
  openedmessage: boolean;
  message: string = '';
  ismessageTextboxVisible: boolean;
  optedOutReadSMSMessage: string;
  twiliosmsApiData: TwilioSMSClaimDetails[];
  twiliosmsdata: TwilioSMSClaimDetails[];

  constructor(@Inject(TwilioSmsClaimdetailsService) private twiliosmsclaimdetailsservice: TwilioSmsClaimdetailsService,  private fb: FormBuilder,private UserAuthService:UserAuthService) {
    this.allData = this.allData.bind(this);

   }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.twiliosmsApiData, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  ngOnInit(): void {
    this.twiliosmsclaimdetailsservice.getTwilioSMSClaimDetails().subscribe(data=>{
      this.twiliosmsApiData = data?.reverse();
    })
  }
  public open(optedOutReadSMSMessage){
    this.Title = "Opted Out Read SMS Message";
    this.opened = true;
     this.optedOutReadSMSMessage= optedOutReadSMSMessage;
    this.showTextarea();
  }

  public showTextarea() {
    this.isTextareaVisible = true;
  }

  public close(status: string): void {
    this.opened = false;
    this.openedmessage = false;
  }
  public openmessage(message){
    this.Title = "Message";
    this.openedmessage = true;
    this.message= message;
    this.showmessageTextbox();
  }

  public showmessageTextbox() {
    this.ismessageTextboxVisible = true;
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.twiliosmsdata = process(this.twiliosmsApiData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "id",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "phoneNumber",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "claimId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "docketType",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdDate",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedDate",
            operator: "contains",
            value: inputValue,
          }
        ],
      },
    }).data;

    // this.dataBinding?.skip = 0;
  }

}
