import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ClientConfig } from 'src/app/models/config.model';
import { ClientService } from 'src/app/services/client.service';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Client } from 'src/app/interfaces/client';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { JobSetupService } from 'src/app/services/job-setup.service';
import { JobSetup } from 'src/app/models/config.model';
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { TriggerEventConfigDetails } from 'src/app/models/config.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss'],
})
export class ClientConfigComponent {
  public clientApiData: ClientConfig;
  public jobSetupApiData: JobSetup;
  public triggerEventApiData: TriggerEventConfigDetails;

  @Input()
  clientConfigurationId: number = 0;
  public selectedClientValue: Number = 0;
  public selectedOrgValue: Number = 0;
  public selectedSourceFilterValue: Number = 0;


  public clientconfig: ClientConfig[] = [];

  public clientForm: FormGroup;
  LoggedInName: string;
  get getFormControl() { return this.clientForm.controls; }

  public isEditMode: boolean = false;
  @Input() sourceSystemMasterData: Sourcesystem[] = [];
  filteredSourceSystem: Sourcesystem[] = [];


  @Input() clientMasterData: Client[] = [];
  filteredClientData: Client[] = [];

  @Input() orgMasterData: Organizations[] = [];
  filteredOrgData: Organizations[] = [];

  public clientValidator: any = {};

  constructor(@Inject(ClientService) private clientService: ClientService, @Inject(JobSetupService) private jobSetupService: JobSetupService, @Inject(TriggerEventService) private triggerEventService: TriggerEventService, @Inject(MasterdataService) private masterdataService: MasterdataService, private fb: FormBuilder, private dialogService: DialogService, private _location: Location) {


    this.clientForm = this.fb.group({
      clientConfigurationId: new FormControl(),
      clientId: new FormControl(),
      clientName: new FormControl(this.clientValidator.clientName, [Validators.required]),
      createdBy: new FormControl(),
      firstClaimIdToProcess: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$")]),
      isActive: new FormControl(true),
      isNotificationNeededForFailedClaims: new FormControl(true),
      bcpClientId: new FormControl(this.clientValidator.bcpClientId, [Validators.required]),
      lastProcessedClaimId: new FormControl(),
      fetchPreviousClaims: new FormControl(false),
      lastProcessedTriggerObjectId: new FormControl(),
      organizationId: new FormControl(this.clientValidator.organizationId, [Validators.required]),
      sourceSystemId: new FormControl(this.clientValidator.sourceSystemId, [Validators.required])
    })
  }

  ngOnInit(): void {
    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredClientData = this.clientMasterData;
    this.filteredOrgData = this.orgMasterData;
    if (this.clientConfigurationId == 0) {
      this.clientConfigurationId = parseInt(localStorage.getItem("clientConfigurationId"));
    }
    if (this.clientConfigurationId > 0) {
      this.isEditMode = true;
      this.getClientData(this.clientConfigurationId);
    }
  }

  public clientFilter(value: string) {
    this.filteredClientData = this.clientMasterData;
    this.filteredClientData = this.clientMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public orgFilter(value: string) {
    this.filteredOrgData = this.orgMasterData;
    this.filteredOrgData = this.orgMasterData.filter(
      (s) => s.orgName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public sourceFilter(value: string) {
    this.filteredSourceSystem = this.sourceSystemMasterData;
    this.filteredSourceSystem = this.sourceSystemMasterData.filter(
      (s) => s.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public getFormData(): any {
    let data: ClientConfig = new ClientConfig();
    this.LoggedInName = localStorage.getItem('username');

    const formValue = this.clientForm.getRawValue();
    if (this.isEditMode) {
      data.id = Number(localStorage.getItem('clientConfigurationId'));
      data.clientId = Number(localStorage.getItem('clientId'));
      data.clientName = formValue.clientName;

    }
    else {
      data.clientId = formValue.clientName.id;
      data.clientName = formValue.clientName.name;
      localStorage.setItem('clientName', data.clientName);
    }
    data.organizationId = +formValue.organizationId?.id ? +formValue.organizationId.id : 0;
    data.sourceSystemId = +formValue.sourceSystemId?.id ? +formValue.sourceSystemId.id : 0;
    data.isActive = formValue.isActive;
    data.fetchPreviousClaims = formValue.fetchPreviousClaims;
    data.firstClaimIdToProcess = +formValue.firstClaimIdToProcess;
    data.lastProcessedClaimId = +formValue.lastProcessedClaimId;
    data.lastProcessedTriggerObjectId = +formValue.lastProcessedTriggerObjectId;
    data.isNotificationNeededForFailedClaims = formValue.isNotificationNeededForFailedClaims;
    data.bcpClientId = formValue.bcpClientId
    if (+this.isEditMode > 0) {
      data.modifiedBy = this.LoggedInName;
      // data.modifiedBy = localStorage.getItem(this.storageKeys.createdBy);
    }
    else {
      data.createdBy = this.LoggedInName;
      //data.createdBy = localStorage.getItem(this.storageKeys.createdBy);
    }

    let sourceSystemName = this.sourceSystemMasterData.find(item => item.id === data.sourceSystemId).name;
    //console.log("source", this.source);
    //console.log(data.sourceSystemId);
    //console.log("sourceSystem", sourceSystemName);
    localStorage.setItem('clientId', data.clientId?.toString());
    localStorage.setItem('isActive', data.isActive.toString());
    localStorage.setItem('clientName', data.clientName);
    localStorage.setItem('sourceSystem', sourceSystemName);
    localStorage.setItem('orgCode', data.organizationId.toString());
    return data;
  }

  public saveClient() {
    let data = this.getFormData();
    if (this.clientConfigurationId > 0) {
      this.putClientData(data);
      this.updateRelatedData(data);
    } else {
      this.postClientData(data);
      // this.clientForm.pristine = false;
      // document.getElementById('disabledTabs').innerText = this.clientConfigurationId?this.clientConfigurationId.toString():"";
    }
  }

  public updateRelatedData(data: any) {
    this.jobSetupService.GetClientJobSetUpByClientConfigurationId(this.clientConfigurationId).subscribe(jobSetupData => {
      jobSetupData.isActive = data.isActive;
      jobSetupData.id = jobSetupData.clientJobSetupId;

      if (jobSetupData.id) {
        this.jobSetupService.putClientJobSetup(jobSetupData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Client job setup updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
    this.triggerEventService.getClientTriggerEventConfigurationDetails(this.clientConfigurationId).subscribe(triggerEventData => {
      triggerEventData.isActive = data.isActive;
      triggerEventData.id = triggerEventData.clientTriggerEventConfigurationId;

      if (triggerEventData.id > 0) {
        this.triggerEventService.updateClientTriggerEventConfiguration(triggerEventData).subscribe((resp) => {
          if (resp.isSuccess) {
            console.log("Trigger event updated successfully");
          }
          else {
            console.log("Error: " + resp.message);
          }
        })
      }
    })
  }

  public putClientData(data: any) {
    this.clientService.putClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client configuration updated successfully");
        console.log("Client configuration updated successfully");
        this.getClientData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public postClientData(data: any) {

    this.clientService.postClient(data).subscribe((resp) => {
      if (resp.isSuccess) {
        this.clientConfigurationId = resp.id;
        this.showConfirmation("Success", "Client configuration added successfully");
        console.log("Client configuration added successfully");
        this.getClientData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log(error.message);
    });
  }

  public getClientData(clientConfigurationId: number) {
    if (clientConfigurationId > 0) {
      this.clientService.getClientConfiguration(clientConfigurationId).subscribe(data => {
        this.clientApiData = data;
        if (this.clientApiData) {

          this.clientForm.patchValue({
            organizationId: this.orgMasterData.find(item => item.id === this.clientApiData.organizationId),
            sourceSystemId: this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId),
            firstClaimIdToProcess: this.clientApiData.firstClaimIdToProcess,
            bcpClientId: this.clientApiData.bcpClientId,
            isActive: this.clientApiData.isActive,
            fetchPreviousClaims: this.clientApiData.fetchPreviousClaims,
            isNotificationNeededForFailedClaims: this.clientApiData.isNotificationNeededForFailedClaims,
            clientName: this.clientApiData.clientName
          });
          let sourceSystemId = this.sourceSystemMasterData.find(item => item.id === this.clientApiData.sourceSystemId).name;
          localStorage.setItem('clientId', this.clientApiData.clientId?.toString());
          localStorage.setItem('clientConfigurationId', this.clientConfigurationId.toString());
          localStorage.setItem('isActive', this.clientApiData.isActive.toString());
          localStorage.setItem('clientName', this.clientApiData?.clientName);
          localStorage.setItem('sourceSystem', sourceSystemId);
          localStorage.setItem('orgCode', this.clientApiData.organizationId?.toString());
          // document.getElementById('disabledTabs').innerText = this.clientConfigurationId ? this.clientConfigurationId.toString():"";
        }
      });
    }
  }

  public exit() {
    if (this.clientForm.dirty) {
      this.showConfirmationOnBack("Please Confirm","You have unsaved data on the page.Do you want to continue?");
    } else {
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {

    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
      }
      this.result = JSON.stringify(result);
    });
  }


  public showConfirmationOnBack(dialogTitle,dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Yes" }, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
      minWidth: 250

    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else if (result.text == "Yes") {
          //this._location.back();
          window.location.reload();
        }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.isEditMode) {
      if (!this.clientForm.valid || !this.clientForm.dirty) {
        return true;
      }
    } else {
      if (!this.clientForm.valid) {
        return true;
      }
    }
    return false;
  }



}
